import { IUser, UserType } from "@ndee/mylab-common/dist";
import { BaseCommandProvider } from "../commands/BaseCommandProvider";
import { IAppItemType } from "../types/IAppItemType";

export const userAppType: IAppItemType<IUser> = {
  commands: new BaseCommandProvider<IUser>(UserType),
  editorRouterLink: "user",
  id: "user",
  itemType: UserType,
  listRouterLink: "users"
};
