import { IonItem, IonLabel, IonNote } from "@ionic/react";
import { IDocument } from "@ndee/mylab-common/dist";
import React from "react";
import { UploadService } from "../UploadService";
import { FileDropzone } from "./FileDropZone";
import { IProperty } from "./IPropertyProps";

type IProps = IProperty<IDocument[]>;

type IState = Pick<IProps, "value">;

const uploadService = new UploadService();

export function DocumentInputRenderer(props: IProps) {
  return (
    <DocumentInputComponent
      id={props.id}
      type={props.type}
      value={props.value}
      item={props.item}
      onValueChanged={props.onValueChanged}
    />
  );
}

export class DocumentInputComponent extends React.Component<IProps, IState> {
  public render() {
    const items =
      this.props.value && Array.isArray(this.props.value) ? (
        this.props.value.map(doc => (
          <IonItem key={doc.name}>
            <IonLabel>
              <a href={doc.url}>{doc.name}</a>
            </IonLabel>
          </IonItem>
        ))
      ) : (
        <IonItem>
          <IonLabel>No files</IonLabel>
        </IonItem>
      );
    return (
      <>
        <IonItem>
          <IonLabel position="stacked">{this.props.type.label}</IonLabel>
          {this.props.type.description ? (
            <IonNote>{this.props.type.description}</IonNote>
          ) : (
            <></>
          )}
        </IonItem>
        {items}
        <IonItem>
          {this.props.value && Array.isArray(this.props.value) ? (
            <FileDropzone onDrop={this.handleFileDrop.bind(this)} />
          ) : (
            <></>
          )}
        </IonItem>
      </>
    );
  }

  private async handleFileDrop(files: FileList) {
    const downloadUrl = await uploadService.upload(files[0]);
    const newValue: IDocument[] = [
      ...this.props.value,
      {
        name: files[0].name,
        storageType: "external",
        url: downloadUrl
      }
    ];
    this.props.onValueChanged(this.props.id, newValue);
  }
}
