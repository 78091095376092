import { IPhdThesis, PhdThesisType } from "@ndee/mylab-common/dist";
import { BaseCommandProvider } from "../commands/BaseCommandProvider";
import { IAppItemType } from "../types/IAppItemType";

export const phdthesisAppType: IAppItemType<IPhdThesis> = {
  commands: new BaseCommandProvider<IPhdThesis>(PhdThesisType),
  editorRouterLink: "phdthesis",
  id: "phdthesis",
  itemType: PhdThesisType,
  listRouterLink: "phdtheses"
};
