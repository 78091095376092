import { IPresentation, PresentationType } from "@ndee/mylab-common/dist";
import { BaseCommandProvider } from "../commands/BaseCommandProvider";
import { IAppItemType } from "../types/IAppItemType";

export const presentationAppType: IAppItemType<IPresentation> = {
  commands: new BaseCommandProvider<IPresentation>(PresentationType),
  editorRouterLink: "presentation",
  id: "presentation",
  itemType: PresentationType,
  listRouterLink: "presentations"
};
