import { IonInput, IonItem, IonLabel, IonNote } from "@ionic/react";
import React from "react";
import { IProperty } from "./IPropertyProps";
export function TextInputRenderer(props: IProperty<string>): JSX.Element {
  return (
    <IonItem>
      <IonLabel position="stacked">{props.type.label}</IonLabel>
      <IonInput
        id={props.id}
        type={props.type.dataType === "number" ? "number" : "text"}
        readonly={props.type.readonly}
        value={props.value}
        onIonChange={e =>
          props.onValueChanged(props.id, (e.currentTarget as any).value)
        }
      />
      {props.type.description ? (
        <IonNote>{props.type.description}</IonNote>
      ) : (
        <></>
      )}
    </IonItem>
  );
}
