import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { Environment, environment } from "./getEnvironment";

const configs = {
  production: {
    apiKey: "AIzaSyC4uURntnFIiIR9Z2eGnDlXriURPaSbY40",
    appId: "1:660138080382:web:ad0347432f421b2842002f",
    authDomain: "mylab3-aa756.firebaseapp.com",
    databaseURL: "https://mylab3-aa756.firebaseio.com",
    messagingSenderId: "660138080382",
    projectId: "mylab3-aa756",
    storageBucket: "mylab3-aa756.appspot.com"
  },
  development: {
    apiKey: "AIzaSyAWK7_Vx006TVl2B4PvXsBSTPNwo9_1y_o",
    appId: "1:51397335557:web:ac054bebffcdb23ff25a6c",
    authDomain: "mylab3-dev.firebaseapp.com",
    databaseURL: "https://mylab3-dev.firebaseio.com",
    measurementId: "G-5YVWN63CJD",
    messagingSenderId: "51397335557",
    projectId: "mylab3-dev",
    storageBucket: "mylab3-dev.appspot.com"
  }
};

let app: firebase.app.App;

export function initFirebaseApp(): firebase.app.App {
  if (!app) {
    app = firebase.initializeApp(
      environment === Environment.Production
        ? configs[Environment.Production]
        : configs[Environment.Development]
    );
    if (environment === Environment.Emulator) {
      app.functions().useEmulator("localhost", 5001);
      app.firestore().useEmulator("localhost", 8081);
    }
  }
  return app;
}
