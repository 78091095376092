import { BackupType, IBackup } from "@ndee/mylab-common/dist";
import { BaseCommandProvider } from "../commands/BaseCommandProvider";
import { IAppItemType } from "../types/IAppItemType";

export const backupAppType: IAppItemType<IBackup> = {
  commands: new BaseCommandProvider<IBackup>(BackupType),
  editorRouterLink: "backup",
  id: "backup",
  itemType: BackupType,
  listRouterLink: "backups"
};
