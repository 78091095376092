import { ISirna, SirnaType } from "@ndee/mylab-common/dist";
import { BaseCommandProvider } from "../commands/BaseCommandProvider";
import { IAppItemType } from "../types/IAppItemType";

export const sirnaAppType: IAppItemType<ISirna> = {
  commands: new BaseCommandProvider<ISirna>(SirnaType),
  editorRouterLink: "sirna",
  id: "sirna",
  itemType: SirnaType,
  listRouterLink: "sirnas"
};
