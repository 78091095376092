import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItemDivider,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar
} from "@ionic/react";
import { IItem, PropertyCategory } from "@ndee/mylab-common/dist";
import "firebase/firestore";
import { arrowBack, refresh, save, trash } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import useRouter from "use-react-router";
import { AppToolbarButtons } from "../components/AppToolbarButtons";
import { IPropertyProps } from "../components/IPropertyProps";
import { ItemFab } from "../components/ItemFab";
import { PropertyGroup } from "../components/PropertyGroup";
import { useItem } from "../hooks/useItem";
import { IAppItemType } from "../types/IAppItemType";
import { deepEqual } from "../util";

export type IIndexedItem<T extends IItem> = Partial<{ [key: string]: any } & T>;

interface IParams<T extends IItem> {
  id?: string;
  appItemType: IAppItemType<T>;
}

export function ItemEditor<T extends IItem>(params: IParams<T>): JSX.Element {
  let { id } = params;
  const {
    appItemType,
    appItemType: { itemType }
  } = params;
  if (!id) {
    id = "";
  }
  const isCreate = !id;
  const [doc] = useItem({ type: itemType, id });
  const [editedDoc, setEditedDoc] = useState<T>(itemType.factory());
  const [editLoading, setEditLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [toast, setToast] = useState<{
    error?: boolean;
    msg?: string;
    open?: boolean;
  }>({
    error: false,
    msg: "",
    open: false
  });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const router = useRouter();
  const itemName = editedDoc[itemType.nameProperty];

  useEffect(() => {
    if (id) {
      setEditLoading(true);
    }
  }, [id]);

  useEffect(() => {
    if (doc) {
      setEditedDoc(doc);
      setEditLoading(false);
    }
  }, [doc]);

  useEffect(() => {
    if (!id) {
      setEditedDoc(itemType.factory());
      setEditLoading(false);
    }
  }, [id, itemType]);

  useEffect(() => {
    setHasChanges(
      isCreate ||
        !deepEqual(
          doc ? doc.serialize() : null,
          editedDoc ? editedDoc.serialize() : null
        )
    );
  }, [doc, editedDoc, isCreate]);

  const propertyTypes = itemType.properties;
  const properties = Object.keys(propertyTypes)
    .filter(p => !propertyTypes[p].hidden)
    .map(p => {
      return {
        id: p,
        onValueChanged: handlePropertyChange,
        type: propertyTypes[p]
      } as IPropertyProps<any>;
    });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton id="back" routerLink={`/${appItemType.listRouterLink}`}>
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
          {editedDoc ? (
            <IonTitle slot="start">
              {isCreate ? "Create " : " "}
              {itemType.label.singular}
              <strong> {itemName}</strong>
            </IonTitle>
          ) : (
            <IonTitle slot="start">{itemType.label.singular} ...</IonTitle>
          )}
          <AppToolbarButtons />
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonLoading isOpen={editLoading} message={"Working..."} />
        <IonList>
          {createPropertyGroup("Identification")}
          {createPropertyGroup("Storage")}
          {createPropertyGroup("Data")}
          {createPropertyGroup("Files")}
          {createPropertyGroup("Changes")}
        </IonList>
      </IonContent>

      <ItemFab onAddClick={() => handleSaveAndAdd()} />

      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={() => setShowDeleteAlert(false)}
        header={"Confirm!"}
        message={`Do you really want to <strong> delete ${itemType.label.singular.toLocaleLowerCase()} ${
          editedDoc.number ? `#${editedDoc.number}` : ""
        } ${editedDoc.name}</strong> ?`}
        buttons={[
          {
            cssClass: "ok",
            handler: performDelete,
            text: "Okay"
          },
          {
            cssClass: "cancel",
            handler: () => {
              setShowDeleteAlert(false);
            },
            role: "cancel",
            text: "Cancel"
          }
        ]}
      />

      <IonToast
        isOpen={!!toast.open}
        message={toast.msg}
        color={toast.error ? "danger" : "primary"}
        onDidDismiss={e => setToast({ open: false })}
        duration={toast.error ? 10000 : 2000}
      />

      <IonFooter>
        {editedDoc ? (
          <IonToolbar color="light">
            <IonButtons slot="primary">
              <IonButton
                id="save"
                color="primary"
                fill="solid"
                size="large"
                disabled={
                  !hasChanges || (isCreate && !editedDoc.validForCreation)
                }
                onClick={handleSave}
              >
                <IonIcon slot="start" icon={save} />
                Save
              </IonButton>

              <IonButton
                id="discard"
                color="medium"
                disabled={!hasChanges}
                fill="outline"
                size="large"
                onClick={handleDiscard}
              >
                {" "}
                <IonIcon slot="start" icon={refresh} />
                Discard
              </IonButton>
              {!isCreate ? (
                <IonButton
                  id="delete"
                  color="medium"
                  fill="solid"
                  size="large"
                  onClick={handleDelete}
                >
                  <IonIcon slot="start" icon={trash} />
                  Delete
                </IonButton>
              ) : (
                <></>
              )}
            </IonButtons>
          </IonToolbar>
        ) : (
          <IonToolbar />
        )}
      </IonFooter>
    </IonPage>
  );

  async function handleSaveAndAdd() {
    await handleSave();
    router.history.push(`/${appItemType.editorRouterLink}`);
  }

  async function handleSave() {
    setEditLoading(true);
    try {
      if (isCreate) {
        router.history.replace(
          `/${appItemType.editorRouterLink}/${await appItemType.commands.create(
            editedDoc
          )}`
        );
      } else {
        await appItemType.commands.update(editedDoc);
      }
      setToast({
        error: false,
        msg: `${itemType.label.singular} saved`,
        open: true
      });
    } catch (error) {
      setToast({
        error: true,
        msg: `Save failed! Error: ${error}`,
        open: true
      });
    }
    setEditLoading(false);
  }

  function handleDelete() {
    setShowDeleteAlert(true);
  }

  async function performDelete() {
    try {
      await appItemType.commands.delete(editedDoc);
      setToast({
        error: false,
        msg: `${itemType.label.singular} deleted`,
        open: true
      });
      router.history.goBack();
    } catch (error) {
      setToast({
        error: true,
        msg: `Delete failed! Error: ${error}`,
        open: true
      });
    }
  }

  function handleDiscard() {
    if (isCreate) {
      router.history.goBack();
    } else if (doc) {
      setEditedDoc(doc);
    }
  }

  function createPropertyGroup(category: PropertyCategory) {
    return editedDoc && hasPropertiesInCategory(category) ? (
      <>
        <IonItemDivider sticky={true} color="light">
          <IonLabel color="dark">{category}</IonLabel>
        </IonItemDivider>
        <PropertyGroup
          category={category}
          fields={properties}
          item={editedDoc}
        />
      </>
    ) : (
      <></>
    );
  }

  function hasPropertiesInCategory(category: PropertyCategory) {
    return Object.values(itemType.properties).some(
      p => p.category === category
    );
  }

  function handlePropertyChange(propId: string, value: any) {
    if (!editLoading) {
      const newDoc = itemType.factory(editedDoc.serialize()) as any;
      newDoc[propId] = value;
      setEditedDoc(newDoc);
    }
  }
}
