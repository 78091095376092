import {
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonList,
  IonMenu,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React from "react";
import { appItemTypes } from "../appItemTypes";
import { signOut } from "../commands/signOut";
import { useUserState } from "../hooks/useUserState";
import { environment } from "../infra/getEnvironment";

export const AppMenu = (): JSX.Element => {
  const [user] = useUserState();
  return (
    <IonMenu side="end" menuId="app" contentId="main" class="my-custom-menu">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{user ? user.email : "Not logged in"}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {Object.values(appItemTypes).map(type => (
            <IonItem
              key={type.id}
              id={`goto${type.itemType.label.plural}`}
              routerLink={`/${type.listRouterLink}`}
            >
              {type.itemType.label.plural}
            </IonItem>
          ))}
          <IonItem id="gotoAdmin" routerLink="/admin">
            Administration
          </IonItem>
          <IonItem id="signOut" routerLink="/" onClick={() => signOut()}>
            Sign out
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonItem>{environment} environment</IonItem>
      </IonFooter>
    </IonMenu>
  );
};
