import {
  IonItem,
  IonLabel,
  IonNote,
  IonSelect,
  IonSelectOption
} from "@ionic/react";
import React from "react";
import { IProperty } from "./IPropertyProps";
export function FlagInputRenderer(props: IProperty<boolean>) {
  return (
    <IonItem>
      <IonLabel position="stacked">{props.type.label}</IonLabel>
      <IonSelect
        id={props.id}
        placeholder="Select..."
        value={props.value}
        onIonChange={e =>
          props.onValueChanged(props.id, (e.currentTarget as any).value)
        }
      >
        <IonSelectOption value={true}>Yes</IonSelectOption>
        <IonSelectOption value={false}>No</IonSelectOption>
      </IonSelect>
      {props.type.description ? (
        <IonNote>{props.type.description}</IonNote>
      ) : (
        <></>
      )}
    </IonItem>
  );
}
