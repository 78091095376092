import { IMethod, MethodType } from "@ndee/mylab-common/dist";
import { BaseCommandProvider } from "../commands/BaseCommandProvider";
import { IAppItemType } from "../types/IAppItemType";

export const methodAppType: IAppItemType<IMethod> = {
  commands: new BaseCommandProvider<IMethod>(MethodType),
  editorRouterLink: "method",
  id: "method",
  itemType: MethodType,
  listRouterLink: "methods"
};
