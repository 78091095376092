import { IItem, IItemType } from "@ndee/mylab-common/dist";
import algoliasearch from "algoliasearch";
import { useState } from "react";
import { useAsyncEffect } from "use-async-effect";
import { IAlgoliaSearchConfig } from "../config/IConfig";
import { useConfig } from "./useConfig";

const LIMIT = 1000;

interface IParams<T extends IItem> {
  searchTerm: string;
  type: IItemType<T>;
}

export function useFullTextSeach<T extends IItem>(
  params: IParams<T>
): [T[], boolean] {
  const { searchTerm, type } = params;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<T[]>([]);
  const [config] = useConfig<IAlgoliaSearchConfig>("algoliasearch");
  const index = config
    ? algoliasearch(config.applicationId, config.apiKey).initIndex(config.index)
    : null;

  useAsyncEffect(async () => {
    if (!searchTerm) {
      setResult([]);
      setLoading(false);
    }
    if (!!index && !!searchTerm) {
      setLoading(true);
      const hits = (
        await index.search(searchTerm, {
          filters: params.type.id,
          hitsPerPage: LIMIT
        })
      ).hits.map(h => type.factory((h as unknown) as Partial<T>));
      setResult(hits);
      setLoading(false);
    }
  }, [searchTerm]);

  return [result, loading];
}
