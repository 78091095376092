import { EquipmentType, IEquipment } from "@ndee/mylab-common/dist";
import { BaseCommandProvider } from "../commands/BaseCommandProvider";
import { IAppItemType } from "../types/IAppItemType";

export const equipmentAppType: IAppItemType<IEquipment> = {
  commands: new BaseCommandProvider<IEquipment>(EquipmentType),
  editorRouterLink: "equipment",
  id: "equipment",
  itemType: EquipmentType,
  listRouterLink: "equipments"
};
