import { IReagent, ReagentType } from "@ndee/mylab-common/dist";
import { BaseCommandProvider } from "../commands/BaseCommandProvider";
import { IAppItemType } from "../types/IAppItemType";

export const reagentAppType: IAppItemType<IReagent> = {
  commands: new BaseCommandProvider<IReagent>(ReagentType),
  editorRouterLink: "reagent",
  id: "reagent",
  itemType: ReagentType,
  listRouterLink: "reagents"
};
