import { AntibodyType, IAntibody } from "@ndee/mylab-common/dist";
import { BaseCommandProvider } from "../commands/BaseCommandProvider";
import { IAppItemType } from "../types/IAppItemType";

export const antibodyAppType: IAppItemType<IAntibody> = {
  commands: new BaseCommandProvider<IAntibody>(AntibodyType),
  editorRouterLink: "antibody",
  id: "antibody",
  itemType: AntibodyType,
  listRouterLink: "antibodies"
};
