import { IItem, ItemTypeId } from "@ndee/mylab-common/dist";
import { antibodyAppType } from "./appTypes/antibody";
import { backupAppType } from "./appTypes/backup";
import { documentAppType } from "./appTypes/document";
import { equipmentAppType } from "./appTypes/equipment";
import { methodAppType } from "./appTypes/method";
import { methodUpdatedAppType } from "./appTypes/method_updated";
import { nitrogentankAppType } from "./appTypes/nitrogentank";
import { nspresentationAppType } from "./appTypes/nspresentation";
import { phdthesisAppType } from "./appTypes/phdthesis";
import { plasmidAppType } from "./appTypes/plasmid";
import { presentationAppType } from "./appTypes/presentation";
import { publicationAppType } from "./appTypes/publication";
import { reagentAppType } from "./appTypes/reagent";
import { sirnaAppType } from "./appTypes/sirna";
import { userAppType } from "./appTypes/user";
import { IAppItemType } from "./types/IAppItemType";

type AppItemTypeDirectory = { [id in ItemTypeId]: IAppItemType<IItem> };

export const appItemTypes: AppItemTypeDirectory = {
  antibody: antibodyAppType,
  backup: backupAppType,
  document: documentAppType,
  equipment: equipmentAppType,
  method: methodAppType,
  method_updated: methodUpdatedAppType,
  nitrogentank: nitrogentankAppType,
  nspresentation: nspresentationAppType,
  phdthesis: phdthesisAppType,
  plasmid: plasmidAppType,
  presentation: presentationAppType,
  publication: publicationAppType,
  reagent: reagentAppType,
  sirna: sirnaAppType,
  user: userAppType
};
