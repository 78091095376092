import {
  IonItem,
  IonLabel,
  IonNote,
  IonSelect,
  IonSelectOption
} from "@ionic/react";
import {
  AllowedValuesProvider,
  DisabledProvider
} from "@ndee/mylab-common/dist";
import React from "react";
import { IProperty } from "./IPropertyProps";
export function SelectInputRenderer(props: IProperty<string>) {
  return (
    <IonItem>
      <IonLabel position="stacked">{props.type.label}</IonLabel>
      <IonSelect
        id={props.id}
        placeholder="Select..."
        value={props.value}
        disabled={getDisabledState()}
        onIonChange={e =>
          props.onValueChanged(props.id, (e.currentTarget as any).value)
        }
      >
        {getAllowedValues().map(value => (
          <IonSelectOption key={value} value={value}>
            {value}
          </IonSelectOption>
        ))}
      </IonSelect>
      {props.type.description ? (
        <IonNote>{props.type.description}</IonNote>
      ) : (
        <></>
      )}
    </IonItem>
  );
  function getAllowedValues(): string[] {
    if (Array.isArray(props.type.allowedValues)) {
      return props.type.allowedValues;
    } else if (typeof props.type.allowedValues === "function") {
      const provider = props.type.allowedValues as AllowedValuesProvider<
        string
      >;
      return provider(props.item);
    } else {
      return [];
    }
  }
  function getDisabledState(): boolean {
    if (typeof props.type.disabled === "function") {
      const provider = props.type.disabled as DisabledProvider;
      return provider(props.item);
    } else if (props.type.disabled !== undefined) {
      return props.type.disabled;
    } else {
      return false;
    }
  }
}
