import { IPlasmid, PlasmidType } from "@ndee/mylab-common/dist";
import { BaseCommandProvider } from "../commands/BaseCommandProvider";
import { IAppItemType } from "../types/IAppItemType";

export const plasmidAppType: IAppItemType<IPlasmid> = {
  commands: new BaseCommandProvider<IPlasmid>(PlasmidType),
  editorRouterLink: "plasmid",
  id: "plasmid",
  itemType: PlasmidType,
  listRouterLink: "plasmids"
};
