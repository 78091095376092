import { IonInput, IonItem, IonLabel, IonNote } from "@ionic/react";
import React from "react";
import { IProperty } from "./IPropertyProps";
export function DateInputRenderer(props: IProperty<Date>): JSX.Element {
  const dateValue = props.value ? new Date(props.value).toLocaleString() : "";
  return (
    <IonItem>
      <IonLabel position="stacked">{props.type.label}</IonLabel>
      <IonInput
        id={props.id}
        type="text"
        readonly={props.type.readonly}
        value={dateValue}
      />
      {props.type.description ? (
        <IonNote>{props.type.description}</IonNote>
      ) : (
        <></>
      )}
    </IonItem>
  );
}
