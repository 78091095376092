import { DocumentType, ILabDocument } from "@ndee/mylab-common/dist";
import { BaseCommandProvider } from "../commands/BaseCommandProvider";
import { IAppItemType } from "../types/IAppItemType";

export const documentAppType: IAppItemType<ILabDocument> = {
  commands: new BaseCommandProvider<ILabDocument>(DocumentType),
  editorRouterLink: "document",
  id: "document",
  itemType: DocumentType,
  listRouterLink: "documents"
};
