// Import FirebaseAuth and firebase.
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { UserType } from "@ndee/mylab-common/dist";
import firebase from "firebase";
import * as firebaseui from "firebaseui";
import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { initFirebaseApp } from "../infra/getFirebaseApp";

initFirebaseApp();

// Configure FirebaseUI.
const uiConfig: firebaseui.auth.Config = {
  callbacks: {
    signInSuccessWithAuthResult: authResult => {
      console.log(JSON.stringify(authResult));
      void firebase
        .firestore()
        .collection(UserType.collection)
        .doc(authResult.user.uid)
        .set({ fullName: authResult.user.displayName }, { merge: true });
      return true;
    }
  },
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod:
        firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
    }
  ],
  // Redirect to /signedIn after sign in is successful.
  // Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/plasmids"
};

export class SignIn extends React.Component {
  public render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle size="large" slot="start">
              the Big LabOFski
            </IonTitle>
            <IonTitle size="small" slot="end">
              Fackler Lab (CIID)
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <IonRow class="ion-margin-top">
              <IonCol>
                <StyledFirebaseAuth
                  uiConfig={uiConfig}
                  firebaseAuth={firebase.auth()}
                />
              </IonCol>
            </IonRow>
            <IonRow class="ion-margin-top ion-text-center">
              <IonCol>
                <IonText>
                  <p>
                    Sign-in or sign-up to access Fackler Lab. It will be
                    awesome! Yes!
                  </p>
                  <p>
                    <sub>
                      Note: After sign-up your account needs to be activated by
                      an{" "}
                      <a href="mailto:nadine.tibroni@med.uni-heidelberg.de">
                        administrator
                      </a>{" "}
                      .
                    </sub>
                  </p>
                </IonText>
                <IonText />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
}
