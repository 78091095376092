import { IItem, IItemType } from "@ndee/mylab-common/dist";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { IItemCommandProvider } from "../types/IItemCommandProvider";

export class BaseCommandProvider<T extends IItem>
  implements IItemCommandProvider<T> {
  constructor(private itemType: IItemType<T>) {}

  public async create(item: T): Promise<string> {
    const func = firebase.functions().httpsCallable("createItem");
    const newId = (
      await func({
        data: item.serialize(false),
        type: this.itemType.id
      })
    ).data;
    return newId;
  }
  public async delete(item: T): Promise<void> {
    await firebase
      .firestore()
      .collection(this.itemType.collection)
      .doc(item.id)
      .delete();
  }
  public async deleteAll(): Promise<void> {
    await firebase.functions().httpsCallable("deleteAllItems")({
      type: this.itemType.id
    });
  }
  public async update(item: T): Promise<void> {
    const func = firebase.functions().httpsCallable("updateItem");
    await func({
      data: item.serialize(false),
      type: this.itemType.id
    });
  }
  public async format(): Promise<string> {
    const formatItemsFunc = firebase.functions().httpsCallable("formatItems");
    return JSON.stringify(
      (
        await formatItemsFunc({
          type: this.itemType.id
        })
      ).data
    );
  }
  public async initSearchIndex(): Promise<string> {
    const initSearchIndexFunc = firebase
      .functions()
      .httpsCallable("initSearchIndex");
    const result = await initSearchIndexFunc({
      type: this.itemType.id
    });
    return result.data;
  }
}
