import { IItem, IItemType } from "@ndee/mylab-common/dist";
import firebase from "firebase/app";
export function convertFromFirestore<T extends IItem>(
  fsData: any,
  type: IItemType<T>
) {
  if (!fsData) {
    return null;
  }
  const itemData: any = {};
  Object.keys(type.properties).forEach(p => {
    if (type.properties[p].dataType === "date" && fsData[p]) {
      const ts = new firebase.firestore.Timestamp(
        fsData[p].seconds,
        fsData[p].nanoseconds
      );
      itemData[p] = ts.toDate();
    } else {
      itemData[p] = fsData[p];
    }
  });
  return itemData;
}
