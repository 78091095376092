import firebase from "firebase/app";
import "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";

export function useConfig<T>(id: string) {
  return useDocumentData<T>(
    firebase
      .firestore()
      .collection("config")
      .doc(id)
  );
}
