import { IonButtons, IonMenuButton } from "@ionic/react";
import React from "react";

export function AppToolbarButtons() {
  return (
    <IonButtons slot="end">
      <IonMenuButton id="appMenu" menu="app" autoHide={false} />
    </IonButtons>
  );
}
