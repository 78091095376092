import { IonCol, IonGrid, IonItemGroup, IonRow } from "@ionic/react";
import { IItem, PropertyCategory } from "@ndee/mylab-common/dist";
import React from "react";
import { IIndexedItem } from "../pages/ItemEditor";
import { IPropertyProps } from "./IPropertyProps";
import { propertyRenderers } from "./propertyRenderers";

interface IProps<T extends IItem> {
  category: PropertyCategory;
  fields: Array<IPropertyProps<any>>;
  item: IIndexedItem<T> | null;
}

export function PropertyGroup<T extends IItem>(props: IProps<T>): JSX.Element {
  const fields = props.fields.filter(f => f.type.category === props.category);
  const elements = fields.map((propertyProps, index) => {
    const el = createPropertyComponent(propertyProps, props, index);
    return (
      <IonCol
        id={propertyProps.id}
        key={propertyProps.id}
        sizeXs="12"
        sizeSm="12"
        sizeMd="6"
        sizeLg="4"
        sizeXl="3"
      >
        {el}
      </IonCol>
    );
  });
  return (
    <IonItemGroup>
      <IonGrid>
        <IonRow>{elements}</IonRow>
      </IonGrid>
    </IonItemGroup>
  );

  function createPropertyComponent(
    propertyProps: IPropertyProps<any>,
    theProps: IProps<T>,
    index: number
  ) {
    const propType = propertyProps.type;
    return propType.renderer ? (
      React.createElement<any>(
        propertyRenderers[propType.renderer],
        {
          id: propertyProps.id,
          item: props.item,
          onValueChanged: theProps.fields[index].onValueChanged,
          type: propertyProps.type,
          value: theProps.item![propertyProps.id]
        },
        null
      )
    ) : (
      <></>
    );
  }
}
