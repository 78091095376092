import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { add } from "ionicons/icons";
import React from "react";

interface IProps {
  onAddClick: () => void;
}

export function ItemFab(props: IProps) {
  return (
    <IonFab vertical="bottom" horizontal="start" slot="fixed">
      <IonFabButton
        color="primary"
        id="add"
        onClick={props.onAddClick}
        title="Add new..."
      >
        <IonIcon icon={add} />
      </IonFabButton>
    </IonFab>
  );
}
