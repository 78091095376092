import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { IItemType } from "@ndee/mylab-common/dist";
import { arrowDown, arrowUp, checkmark } from "ionicons/icons";
import React, { useState } from "react";
import { ISortSetting } from "../types/ISortSetting";
import { OrderDirection } from "../types/OrderDirection";

interface IProps {
  isOpen: boolean;
  option: ISortSetting;
  itemType: IItemType<any>;
  onChange?: (option: ISortSetting) => void;
}
export function SortOptionsModal(props: IProps): JSX.Element {
  const [sortBy, setSortBy] = useState<string>(props.option.sortBy);
  const [direction, setDirection] = useState<OrderDirection>(
    props.option.direction
  );
  const [startAt, setStartAt] = useState<string>("");
  function apply() {
    if (props.onChange) {
      props.onChange({
        direction,
        sortBy,
        startAt
      });
    }
  }
  function cancel() {
    if (props.onChange) {
      props.onChange(props.option);
    }
  }
  function toggleDirection() {
    setDirection(
      direction === OrderDirection.Descending
        ? OrderDirection.Ascending
        : OrderDirection.Descending
    );
  }
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Sort options</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonLabel>Sort by</IonLabel>
            <IonSelect
              slot="end"
              onIonChange={e => {
                setSortBy(e.detail.value);
                setStartAt("");
              }}
              value={sortBy}
            >
              {Object.keys(props.itemType.properties)
                .filter(p => props.itemType.properties[p].sortable)
                .map(p => (
                  <IonSelectOption key={p} value={p}>
                    <IonIcon hidden={sortBy !== p} icon={checkmark} />
                    {props.itemType.properties[p].label}
                  </IonSelectOption>
                ))}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonButton
              slot="end"
              color="medium"
              size="small"
              onClick={() => toggleDirection()}
            >
              <IonIcon icon={direction === "asc" ? arrowUp : arrowDown} />
              {direction === "asc" ? "Ascending" : "Descending"}
            </IonButton>
          </IonItem>
          <IonItem>
            <IonLabel>Start at</IonLabel>
            <IonInput
              placeholder="abc.. 123.."
              slot="end"
              value={startAt}
              onIonChange={e => {
                setStartAt(e.detail.value ? e.detail.value.toString() : "");
              }}
            />
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="primary">
            <IonButton
              color="primary"
              fill="solid"
              size="large"
              onClick={() => apply()}
            >
              Apply
            </IonButton>
            <IonButton
              color="medium"
              fill="outline"
              size="large"
              onClick={() => cancel()}
            >
              Cancel
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </>
  );
}
