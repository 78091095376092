import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthenticatedRoute } from "./AuthenticatedRoute";
import { appItemTypes } from "./appItemTypes";
import { AppMenu } from "./components/AppMenu";
import { Admin } from "./pages/Admin";
import { ItemBrowser } from "./pages/ItemBrowser";
import { ItemEditor } from "./pages/ItemEditor";
import { SignIn } from "./pages/SignIn";
import { SignOut } from "./pages/SignOut";
import "./theme.css";
/* Theme variables */
import "./theme/variables.css";
// Dummy change

const App: React.FC = () => (
  <IonApp>
    <AppMenu />
    <IonReactRouter>
      <IonRouterOutlet id="main">
        <Route exact={true} path="/signin" render={() => <SignIn />} />
        <AuthenticatedRoute
          exact={true}
          path="/signout"
          render={() => <SignOut />}
        />
        <Route
          exact={true}
          path="/"
          render={() => <Redirect to="/plasmids" />}
        />
        <AuthenticatedRoute
          exact={true}
          path="/admin"
          render={() => <Admin />}
        />
        {Object.values(appItemTypes).map(appItemType => (
          <AuthenticatedRoute
            key={`${appItemType.id}}List`}
            exact={true}
            path={`/${appItemType.listRouterLink}`}
            render={() => <ItemBrowser appItemType={appItemType} />}
          />
        ))}
        {Object.values(appItemTypes).map(appItemType => (
          <Route
            key={`${appItemType.id}}Editor`}
            exact={true}
            path={`/${appItemType.editorRouterLink}/:id?`}
            render={routeProps => (
              <ItemEditor
                id={routeProps ? (routeProps.match.params.id as string) : ""}
                appItemType={appItemType}
              />
            )}
          />
        ))}
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
