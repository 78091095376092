import { PropertyRendererType } from "@ndee/mylab-common/dist";
import { DateInputRenderer } from "./DateInputRenderer";
import { DocumentInputRenderer } from "./DocumentInputRenderer";
import { FlagInputRenderer } from "./FlagInputRenderer";
import { SelectInputRenderer } from "./SelectInputRenderer";
import { TextInputRenderer } from "./TextInputRenderer";

export const propertyRenderers: Record<PropertyRendererType, React.FC<any>> = {
  Date: DateInputRenderer,
  Document: DocumentInputRenderer,
  Flag: FlagInputRenderer,
  Select: SelectInputRenderer,
  Text: TextInputRenderer
};
