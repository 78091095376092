import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLoading,
  IonModal,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { IItem } from "@ndee/mylab-common/dist";
import { funnelOutline } from "ionicons/icons";
import React, { useCallback, useState } from "react";
import useRouter from "use-react-router";
import { AppToolbarButtons } from "../components/AppToolbarButtons";
import { ItemFab } from "../components/ItemFab";
import { ItemList } from "../components/ItemList";
import { SearchResultItem } from "../components/SearchResultItem";
import { SortOptionsModal } from "../components/SortOptionsModal";
import { SortSettingItem } from "../components/SortSettingItem";
import { useFullTextSeach } from "../hooks/useFullTextSearch";
import { useItems } from "../hooks/useItems";
import { IAppItemType } from "../types/IAppItemType";
import { ISortSetting } from "../types/ISortSetting";
import { OrderDirection } from "../types/OrderDirection";

const LIMIT = 100;

interface IParams<T extends IItem> {
  appItemType: IAppItemType<T>;
}

export function ItemBrowser<T extends IItem>(params: IParams<T>) {
  const { appItemType } = params;
  const [showSortOptionsModal, setShowSortOptionsModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortSetting, setSortSetting] = useState<ISortSetting>({
    direction: OrderDirection.Ascending,
    sortBy: appItemType.itemType.defaultOrderBy,
    startAt: ""
  });
  const [limit, setLimit] = useState(LIMIT);
  const [sortResult, sortLoading] = useItems({
    defaultOrderBy: appItemType.itemType.defaultOrderBy,
    limit,
    sort: sortSetting,
    type: appItemType.itemType
  });
  const [searchResult, searchLoading] = useFullTextSeach<T>({
    searchTerm,
    type: appItemType.itemType
  });
  const items = searchTerm ? searchResult : sortResult;
  const loading = sortLoading || searchLoading;
  const router = useRouter();
  const onSortChange = useCallback(e => setSortSetting(e), [setSortSetting]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle class="desktop" slot="start">
            {appItemType.itemType.label.plural}
          </IonTitle>
          <IonSearchbar
            class="ion-text-left"
            enterkeyhint="enter"
            slot="end"
            id="searchbar"
            value={searchTerm}
            placeholder={`${appItemType.itemType.label.plural}...`}
            onKeyUp={e => {
              if (e.key === "Enter") {
                setSearchTerm(
                  e.currentTarget.value ? e.currentTarget.value : ""
                );
              }
            }}
            onIonChange={e => {
              if (!e.detail.value) {
                setSearchTerm("");
              }
            }}
          />
          <AppToolbarButtons />
        </IonToolbar>
      </IonHeader>
      <ItemFab
        onAddClick={() =>
          router.history.push(`/${appItemType.editorRouterLink}`)
        }
      />
      <IonContent>
        <IonLoading isOpen={loading} message={"Loading..."} />

        {searchTerm ? (
          <SearchResultItem
            itemType={appItemType.itemType}
            onCancel={() => setSearchTerm("")}
            searchResult={searchResult}
          />
        ) : (
          <SortSettingItem
            itemType={appItemType.itemType}
            setting={sortSetting}
            onChange={onSortChange}
          />
        )}

        <ItemList
          appItemType={appItemType}
          itemType={appItemType.itemType}
          items={items}
          additionalProperties={[sortSetting.sortBy]}
        />
        <IonModal isOpen={showSortOptionsModal}>
          <SortOptionsModal
            isOpen={showSortOptionsModal}
            itemType={appItemType.itemType}
            option={sortSetting}
            onChange={e => {
              setSortSetting(e);
              setShowSortOptionsModal(false);
            }}
          />
        </IonModal>
        {items.length >= LIMIT ? (
          <IonButton
            expand="full"
            color="medium"
            onClick={() => setLimit(limit + LIMIT)}
          >
            Load more...
          </IonButton>
        ) : (
          <></>
        )}
      </IonContent>
      <IonFooter>
        <IonToolbar color="light">
          <IonButtons slot="primary">
            <IonButton
              color="primary"
              fill="solid"
              size="large"
              disabled={!!searchTerm}
              onClick={() => setShowSortOptionsModal(true)}
            >
              <IonIcon slot="start" icon={funnelOutline} />
              Sort
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
}
