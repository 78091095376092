import { IItem, IItemType } from "@ndee/mylab-common/dist";
import firebase from "firebase/app";
import "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { ISortSetting } from "../types/ISortSetting";
import { convertFromFirestore } from "../util";

interface IParams<T extends IItem> {
  sort: ISortSetting;
  defaultOrderBy: string;
  limit: number;
  type: IItemType<T>;
}

export function useItems<T extends IItem>(params: IParams<T>): [T[], boolean] {
  let query: firebase.firestore.Query;
  const { startAt, sortBy, direction } = params.sort;
  if (startAt) {
    const numericStartAt = parseInt(startAt, 10);
    const realStartAt = isNaN(numericStartAt) ? startAt : numericStartAt;
    query = firebase
      .firestore()
      .collection(params.type.collection)
      .orderBy(sortBy ? sortBy : params.defaultOrderBy, direction)
      .startAt(realStartAt ? realStartAt : 0)
      .limit(params.limit);
  } else {
    query = firebase
      .firestore()
      .collection(params.type.collection)
      .orderBy(sortBy ? sortBy : params.defaultOrderBy, direction)
      .limit(params.limit);
  }
  const [data, loading] = useCollection(query, {
    snapshotListenOptions: { includeMetadataChanges: true }
  });
  return [
    data && data.docs
      ? data.docs
          .filter((d: any) => !(d.data() as T).hidden)
          .map((d: any) => {
            return params.type.factory({
              ...convertFromFirestore<T>(d.data(), params.type),
              id: d.id
            } as Partial<T>);
          })
      : [],
    loading
  ];
}
