import {
  INonScientificPresentation,
  NonScientificPresentationType
} from "@ndee/mylab-common/dist";
import { BaseCommandProvider } from "../commands/BaseCommandProvider";
import { IAppItemType } from "../types/IAppItemType";

export const nspresentationAppType: IAppItemType<INonScientificPresentation> = {
  commands: new BaseCommandProvider<INonScientificPresentation>(
    NonScientificPresentationType
  ),
  editorRouterLink: "nspresentation",
  id: "nspresentation",
  itemType: NonScientificPresentationType,
  listRouterLink: "nspresentations"
};
