import {
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { IItemType } from "@ndee/mylab-common/dist";
import { arrowDown, arrowUp } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { ISortSetting } from "../types/ISortSetting";
import { OrderDirection } from "../types/OrderDirection";

interface IProps {
  hidden?: boolean;
  setting: ISortSetting;
  itemType: IItemType<any>;
  onChange?: (option: ISortSetting) => void;
}
export function SortSettingItem(props: IProps): JSX.Element {
  const [sortBy, setSortBy] = useState<string>(props.setting.sortBy);
  const [direction, setDirection] = useState<OrderDirection>(
    props.setting.direction
  );
  const [startAt, setStartAt] = useState<string>("");
  const [showSortByModal, setShowSortByModal] = useState(false);
  const [showStartAtModal, setShowStartAtModal] = useState(false);
  const { onChange } = props;

  function toggleDirection() {
    setDirection(
      direction === OrderDirection.Ascending
        ? OrderDirection.Descending
        : OrderDirection.Ascending
    );
  }

  useEffect(() => {
    if (onChange) {
      onChange({
        direction,
        sortBy,
        startAt
      });
    }
  }, [onChange, direction, sortBy, startAt]);

  return !props.hidden ? (
    <>
      <IonItem class="desktop">
        <IonLabel class="ion-text-center">
          All <strong>{props.itemType.label.plural}</strong> sorted by
          <IonChip onClick={() => setShowSortByModal(true)}>
            <IonLabel color="primary">
              <strong>{props.itemType.properties[sortBy].label}</strong>
            </IonLabel>
          </IonChip>
          <IonChip data-element="direction" onClick={e => toggleDirection()}>
            <IonIcon icon={direction === "asc" ? arrowUp : arrowDown} />
            <IonLabel color="primary">
              <strong>
                {direction === OrderDirection.Ascending
                  ? "ascending"
                  : "descending"}
              </strong>
            </IonLabel>
          </IonChip>
          {startAt ? (
            <IonChip onClick={() => setShowStartAtModal(true)}>
              <IonLabel color="primary">
                <strong>from: {startAt}</strong>
              </IonLabel>
            </IonChip>
          ) : (
            ""
          )}
        </IonLabel>
      </IonItem>

      <IonItem class="mobile">
        <IonLabel class="ion-text-center">
          Sorted by{" "}
          <strong>
            {props.itemType.properties[sortBy].label}{" "}
            {direction === OrderDirection.Ascending
              ? "ascending"
              : "descending"}
          </strong>
        </IonLabel>
      </IonItem>

      <IonModal isOpen={showSortByModal}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Start sorting at</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            {Object.keys(props.itemType.properties)
              .filter(p => props.itemType.properties[p].sortable)
              .map(p => (
                <IonItem
                  key={p}
                  onClick={() => {
                    setSortBy(p);
                    setShowSortByModal(false);
                  }}
                >
                  {props.itemType.properties[p].label}
                </IonItem>
              ))}
          </IonList>
        </IonContent>
      </IonModal>

      <IonModal isOpen={showStartAtModal}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Start sorting at...</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem>
            <IonInput
              placeholder="Enter letter or number to start (ENTER to confirm)"
              onKeyUp={e => {
                if (e.key === "Enter") {
                  setStartAt(
                    e.currentTarget.value
                      ? e.currentTarget.value.toString()
                      : ""
                  );
                  setShowStartAtModal(false);
                }
              }}
            />
          </IonItem>
        </IonContent>
      </IonModal>
    </>
  ) : (
    <></>
  );
}
