import { IItem, IItemType } from "@ndee/mylab-common/dist";
import firebase from "firebase/app";
import "firebase/firestore";
import { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { convertFromFirestore } from "../util/convertFromFirestore";

interface IParams<T extends IItem> {
  id: string;
  type: IItemType<T>;
}

export function useItem<T extends IItem>(
  params: IParams<T>
): [T | undefined, boolean] {
  const { id, type } = params;
  const [item, setItem] = useState<T | undefined>(undefined);
  const collection = firebase.firestore().collection(type.collection);
  const [doc, loading] = useDocument(id ? collection.doc(id) : undefined);

  useEffect(() => {
    const itemData = doc ? convertFromFirestore<T>(doc.data(), type) : null;
    if (doc && itemData && !itemData.hidden) {
      setItem(
        type.factory({
          ...itemData,
          id: doc.id
        })
      );
    }
  }, [doc, type]);

  return [item, loading];
}
