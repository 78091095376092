import { INitrogentank, NitrogentankType } from "@ndee/mylab-common/dist";
import { BaseCommandProvider } from "../commands/BaseCommandProvider";
import { IAppItemType } from "../types/IAppItemType";

export const nitrogentankAppType: IAppItemType<INitrogentank> = {
  commands: new BaseCommandProvider<INitrogentank>(NitrogentankType),
  editorRouterLink: "nitrogentank",
  id: "nitrogentank",
  itemType: NitrogentankType,
  listRouterLink: "nitrogentanks"
};
