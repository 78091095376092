import { IonButton, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { IItem, IItemType } from "@ndee/mylab-common/dist";
import { bulbOutline } from "ionicons/icons";
import React from "react";

interface IProps {
  searchResult: IItem[];
  itemType: IItemType<any>;
  onCancel: () => void;
}
export function SearchResultItem(props: IProps): JSX.Element {
  const { searchResult, itemType, onCancel } = props;
  const searchablePropertyNames = Object.values(itemType.properties)
    .filter(p => p.searchable)
    .map(p => p.label);

  return (
    <>
      <IonItem>
        <IonLabel class="ion-text-center">
          <strong>{searchResult.length} </strong>
          {searchResult.length > 1 ? (
            <span>
              {itemType.label.plural.toLowerCase()}
              <span> match </span>
            </span>
          ) : (
            <span>
              {itemType.label.singular.toLowerCase()}
              <span> matches </span>
            </span>
          )}
          your search.
          {searchResult.length === 0 ? (
            <IonLabel class="ion-margin-top">
              <p>
                <IonIcon icon={bulbOutline} /> Included fields:{" "}
                <strong>{searchablePropertyNames.join(", ")}</strong>
              </p>
            </IonLabel>
          ) : (
            ""
          )}
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonButton slot="end" fill="solid" onClick={() => onCancel()}>
          {" "}
          Browse all
        </IonButton>
      </IonItem>
    </>
  );
}
