import {
  IonButton,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { version } from "@ndee/mylab-common/dist";
import React, { useState } from "react";
import { plasmidAppType } from "../appTypes/plasmid";
import { reagentAppType } from "../appTypes/reagent";
import { AppToolbarButtons } from "../components/AppToolbarButtons";

export const Admin = () => {
  const [formatResult, setFormatResult] = useState("Not started");
  const [initSearchIndexResult, setInitSearchIndexResult] = useState(
    "Not started"
  );
  const [
    initSearchIndexReagentsResult,
    setInitSearchIndexReagentsResult
  ] = useState("Not started");
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle slot="start">Administration</IonTitle>
          <AppToolbarButtons />
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonItem>
          <IonLabel>Common version in app: {version}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>Format Plasmids: {formatResult}</IonLabel>
          <IonButton
            id="formatPlasmids"
            onClick={async () => {
              setFormatResult("Started...");
              const result = await plasmidAppType.commands.format();
              console.log(result);
              setFormatResult(result);
            }}
          >
            Execute
          </IonButton>
        </IonItem>
        <IonItem>
          <IonLabel>
            Init Search Index (Plasmids): {initSearchIndexResult}
          </IonLabel>
          <IonButton
            id="initSearchIndex"
            onClick={async () => {
              setInitSearchIndexResult("Started...");
              const result = await plasmidAppType.commands.initSearchIndex();
              console.log(result);
              setInitSearchIndexResult(result);
            }}
          >
            Execute
          </IonButton>
        </IonItem>
        <IonItem>
          <IonLabel>
            Init Search Index (Reagents): {initSearchIndexReagentsResult}
          </IonLabel>
          <IonButton
            id="initSearchIndex"
            onClick={async () => {
              setInitSearchIndexReagentsResult("Started...");
              const result = await reagentAppType.commands.initSearchIndex();
              console.log(result);
              setInitSearchIndexReagentsResult(result);
            }}
          >
            Execute
          </IonButton>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};
