import firebase from "firebase/app";
import "firebase/storage";

export class UploadService {
  public upload(file: File): Promise<string> {
    return new Promise(resolve => {
      const storageRef = firebase.storage().ref(file.name);
      const task = storageRef.put(file);
      task.on(
        "state_changed",

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        snapshot => {},
        error => {
          throw new Error(`Upload of ${file.name} failed with error ${error}`);
        },
        () => {
          void task.snapshot.ref.getDownloadURL().then(downloadURL => {
            resolve(downloadURL!);
          });
        }
      );
    });
  }
}
