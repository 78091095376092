import { IMethod, MethodUpdatedType } from "@ndee/mylab-common/dist";
import { BaseCommandProvider } from "../commands/BaseCommandProvider";
import { IAppItemType } from "../types/IAppItemType";

export const methodUpdatedAppType: IAppItemType<IMethod> = {
  commands: new BaseCommandProvider<IMethod>(MethodUpdatedType),
  editorRouterLink: "method_updated",
  id: "method_updated",
  itemType: MethodUpdatedType,
  listRouterLink: "methods_updated"
};
