import { IonItem, IonLabel, IonList, IonListHeader } from "@ionic/react";
import { IItem, IItemType } from "@ndee/mylab-common/dist";
import React from "react";
import { IAppItemType } from "../types/IAppItemType";
import { ISortSetting } from "../types/ISortSetting";

interface IProps {
  hidden?: boolean;
  items: IItem[];
  itemType: IItemType<any>;
  appItemType: IAppItemType<any>;
  additionalProperties?: string[];
  onChange?: (option: ISortSetting) => void;
}
export function ItemList(props: IProps): JSX.Element {
  const listProperties = [
    ...new Set([
      ...Object.keys(props.itemType.properties).filter(
        name => props.itemType.properties[name].showInList
      ),
      ...(props.additionalProperties ?? [])
    ])
  ];
  return (
    <IonList hidden={props.hidden}>
      <IonListHeader hidden={props.items.length === 0}>
        {listProperties.map(id => (
          <IonLabel key={id}>
            <strong>{props.itemType.properties[id].label}</strong>
          </IonLabel>
        ))}
      </IonListHeader>
      {props.items.map((item: IItem) => (
        <IonItem
          class="labitem"
          key={item.id}
          routerLink={`/${props.appItemType.editorRouterLink}/${item.id}`}
        >
          {listProperties.map(id => (
            <IonLabel key={id}>{item[id]}</IonLabel>
          ))}
        </IonItem>
      ))}
    </IonList>
  );
}
