import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import { useUserState } from "./hooks/useUserState";
interface IProps {
  exact?: boolean;
  path: string;
  render: (props: any) => JSX.Element;
}

export const AuthenticatedRoute = ({ render }: IProps) => {
  const [user, loading] = useUserState();
  return !loading ? (
    <Route
      render={
        user
          ? theProps => {
              return render(theProps);
            }
          : () => {
              return (
                <Redirect
                  to={{
                    pathname: "/signin"
                  }}
                />
              );
            }
      }
    />
  ) : (
    <></>
  );
};
