import { IPublication, PublicationType } from "@ndee/mylab-common/dist";
import { BaseCommandProvider } from "../commands/BaseCommandProvider";
import { IAppItemType } from "../types/IAppItemType";

export const publicationAppType: IAppItemType<IPublication> = {
  commands: new BaseCommandProvider<IPublication>(PublicationType),
  editorRouterLink: "publication",
  id: "publication",
  itemType: PublicationType,
  listRouterLink: "publications"
};
